////////////////////////////////////////////////////////////////////////////////
//// Open-Layers 5.3 based maps
////////////////////////////////////////////////////////////////////////////////

function initMaps(){
    mapObj = new dvMapOl();
    mapObj.addContlayers(1);
    mapObj.addTooltip();
    mapObj.show();
}
function initDetailMap(){
    $('.map-detail-container').each(function(){
        if(!$(this).hasClass('has-map')){
            var id = $(this).attr('data-id');
            var lon = $(this).attr('data-koorx');
            var lat = $(this).attr('data-koory');
            mapObj = new dvMapOl();
            mapObj.setTarget('map-detail-container-' + id);
            mapObj.setLonLat(lon,lat);
            mapObj.setZoom(13);
            mapObj.addSingleLayer(); 
            mapObj.show(1);
            $(this).addClass('has-map');
        }
    });
}

/////////////////////////////////////////////////////////////////////////////
// variables
///////////////////////////////////////////////////////////////////////////
var projection = ol.proj.get('EPSG:900913');

////////////////////////////////////////////////////////////////////////////////
//// dvMap /////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
var dvMapOl = function () {
    this.target = $('#map-container');
    this.targetmap = $(this.target).find('#map-container-inner');

    var lon = parseFloat($(this.targetmap).attr('data-lon'));
    var lat = parseFloat($(this.targetmap).attr('data-lat'));
    var zoom = parseInt($(this.targetmap).attr('data-zoom')); 
    
    if(lon > 0) this.lon = lon; else this.lon = 8.4;
    if(lat > 0) this.lat = lat; else this.lat = 46.9;
    if(zoom > 0) this.zoom = zoom; else this.zoom = 9;
        
    this.selectedbase = 0;    
    this.mapswitch = 0;    

    this.dv_map = '';
    this.layers = [];
    this.singlelayers = [];
    this.baselayers = [];
    this.contlayers = [];
    this.overlay = null;
    this.tt = null;
    
    // layers hinzufügen
    var attribution = 'Kartendaten: &copy; <a href="https://www.geo.admin.ch/de/geo-services/geo-services/terms-of-use.html">SwissTopo</a>';
    this.baselayers.push(new ol.layer.Tile({ name: 'baselayer_swisstopo', title: 'SwissTopo Map', index: 10, source: new ol.source.XYZ({ url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg', attributions: attribution }), opacity: 1, }));  
    //this.baselayers.push(new ol.layer.Tile({ name: 'baselayer_openstreet', title: 'Open Street Maps', index:20, source: new ol.source.OSM(), visible: false, opacity: 0,}));  
    //var attribution = 'Kartendaten: &copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>-Mitwirkende, <a href="http://viewfinderpanoramas.org">SRTM</a> | Kartendarstellung: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)';
    //this.baselayers.push(new ol.layer.Tile({ name: 'baselayer_opentopo', title: 'Open Topo Map', index: 30, source: new ol.source.XYZ({ url: 'https://opentopomap.org/{z}/{x}/{y}.png', attributions: attribution }), opacity: 0, }));
        
    this.addContlayers = function(a,b){
        if(a > 0) this.contlayers.push(new ol.layer.Vector({ name: 'unterkuenfte',title:'Lagerhäuser',icon:'unterkuenfte-marker.svg', index: 100, visible: true, opacity: 1, source: new ol.source.Vector({ url: mapdomain + '/kml/unterkuenfte', format: new ol.format.KML() })}));  
    };
    
    this.addSingleLayer = function(){
        var markers = [];
        var marker =  new ol.Feature({ geometry: new ol.geom.Point(ol.proj.transform([this.lon, this.lat], 'EPSG:4326', 'EPSG:3857'))});
        var style = new ol.style.Style({ image: new ol.style.Icon(({ anchor: [0.5, 1], src: "/dist/unterkuenfte-marker1.svg",scale:0.025 }))});
        marker.setStyle(style);
        markers.push(marker);
        this.singlelayers.push(new ol.layer.Vector({ name: 'unterkuenfte_detail',title:'Lagerhaus',index: 100, visible: true, opacity: 1, source: new ol.source.Vector({ features: markers })}));  
    };
    
    /////////////////////////////////////////////////////////////////////////////
    // merge layer
    ///////////////////////////////////////////////////////////////////////////
    this.mergeLayers = function(){
        var that = this;
        this.baselayers.forEach(function(lay, i) {
            that.layers.push(lay);
        });
        this.contlayers.forEach(function(lay, i) {
            that.layers.push(lay);
        });
        this.singlelayers.forEach(function(lay, i) {
            that.layers.push(lay);
        });
    };

    this.show = function(noevents){
        if(!noevents) noevents = false;
        var that = this;
        $(this.target).append('<div id="mapPanel"><div id="mapTabs"></div></div>');
        this.mergeLayers();
           
        this.dv_map = new ol.Map({
            target: that.targetmap[0],
            renderer: 'canvas',
            layers: that.layers,
            interactions: eval('ol.interaction.defaults(' + this.interactions + ')'),
            controls: eval('ol.control.defaults(' + this.controls + ')'),
            view: new ol.View({
                projection: 'EPSG:900913',
                center: ol.proj.transform([that.lon, that.lat],'EPSG:4326', 'EPSG:3857'),
                zoom:that.zoom
            })
        })
        
        ////////////////////////////////////////////////////////////////////////
        if(!noevents){
            this.dv_map.on('pointermove', function(evt) {
                var pixel = that.dv_map.getEventPixel(evt.originalEvent);
                that.switchCursor(evt);
            });
            this.dv_map.on('click', function(evt) {
                that.findFeatureID(evt.pixel);
            });
        }
    };
    
    this.switchCursor = function(evt) {
        var target = this.dv_map.getTarget();
        var jTarget = typeof target === "string" ? $("#" + target) : $(target);
        
        if(this.tooltip) this.tooltip.hide(0);
        
        var feature = this.dv_map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
          return feature;
        });
        if (feature) {
            jTarget.css("cursor", "pointer");
            
            var hausname = decodeURI(feature.get('hausname'));
            var standort = decodeURI(feature.get('standort'));
            var foto = decodeURI(feature.get('foto'));
            var plaetze = decodeURI(feature.get('plaetze'));
            
            var innerhtml = '';
            innerhtml += '<h2>' + hausname + '</h2>';
            innerhtml += '<img src="/data/images/saf1/' + foto + '" />';
            innerhtml += '<p><span>Ort:</span>' + standort + '</p>';
            innerhtml += '<p><span>Plätze:</span>' + plaetze + '</p>';
            
            
            this.tooltip.html(innerhtml);
            this.tooltip.css('left',(evt.pixel[0]+10) + 'px').css('top',(evt.pixel[1]+10) + 'px').show(0);
            
        } else {
            jTarget.css("cursor", "");
        }

    }
    this.findFeatureID = function(pixel) {
        var feature = this.dv_map.forEachFeatureAtPixel(pixel, function(feature, layer) {
          return feature;
        });
        if (feature) {
            var ident = feature.get('ident');
            
            if(ident !== 'undefinded'){
                location.href = '/lagerhaus/' + ident;
            }
        }
    };
    this.addTooltip = function() {
        this.tooltip = $('<div class="map-tooltip"></div>');
        this.target.append(this.tooltip);
    };
    this.setTarget = function(tgt) {
        this.target = $('#' + tgt);
        this.targetmap = $('#' + tgt + '-inner');
    };
    this.setLonLat = function(lon,lat) {
        this.lon = parseFloat(lon);
        this.lat = parseFloat(lat);
    };
    this.setZoom = function(zoom) {
        this.zoom = parseInt(zoom);
    };
    
    

};